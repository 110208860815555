
.actionButton {

  border: none;

  color: var(--primary);

  background-color: transparent;

  //&:hover, &:focus, &:active, &.active {
  //  color: var(--medium-brown);
  //}

  &.underline {
    text-decoration: underline;
  }


  &.bold {
    font-weight: 500;
  }

  &.alignStart {
    text-align: start;
    padding-left: 0;
    padding-right: 0;
  }

  &.decorationHover {
    &:hover {
      text-decoration: underline;
    }
  }
}
