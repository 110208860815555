.BackToTop {
  display:none;

}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .BackToTop {

    z-index: 602;
    opacity: 1;

    position:fixed;
    right: 0;
    bottom: 100px;

    background-color: var(--primary);

    color: var(--white);

    display:flex;
    justify-content: center;
    align-items: center;

    padding: 5px 8px 5px 10px;
    box-shadow: var(--box-shadow-dark);

    border-width: 0;
    border-style: none;
    border-radius: 5px 0 0 5px;

    user-select: none;          /* Likely future */

    .linkTop {
      font-size: 14px;
      //text-decoration: underline;
    }
  }
}