.headerBarText {
  color: var(--white);
}

.bookmark {
  margin: .5rem .25rem 0rem;
}

.Header {

  padding-top:12px;
  padding-bottom:0;
  background-color: var(--primary);


  .fullNavBar {
    display:block;

    .rightCol {
      display: flex;
      justify-content: flex-end;

      .sidebarButton {
        background-color: transparent;
        padding:0;
        border: none;

      }

      .dropdown {
        z-index: 502;

        .dropdownContent {
          min-width: 160px;
        }
      }
    }
  }


  .miniNavBar {
    display:none;

    .miniMenuBar {
      display:flex;
      padding-bottom: 10px;

      .miniNavLogo {
        width: auto;
        // padding-top:8px;
      }

      .rightCol {
        display: flex;
        justify-content: flex-end;

        .miniMenuControlButton {
          display: inline-block;
          cursor: pointer;
        }

      }

    }
  }


  .bottomDivider {
    background-color: var(--accent-background);
    width: 100%;
    //height: 1px;
  }
}  // End >> Header



.logoImg {
  &:hover {
    cursor: pointer;
  }
}



.menuBar {
  width:100%;
  display:inline-flex;
  justify-content:space-between;
  padding-left: 10px;
}


.dropdown {
  position: relative;
  display: inline-block;
  z-index:501;

  .menuSelected {
    width:100%;
    height:1px;
    background-color:var(--white)
  }

  .menuBtn {
    background-color: var(--primary);
    color: var(--white);

    //width:auto;
    //height:auto;

    padding-top: 16px;
    padding-bottom: 4px;
    border: none;
  }

  //&:hover {
  //  .menuBtn {
  //    background-color: rgba(255, 255, 255, 0.05);
  //
  //  }
  //}

  .openDropDown {
    display: block !important;
  }

  //&:hover {
  //  .dropdownContent {
  //    display: block;
  //  }
  //}

  .adjustRight {
    right:0;
  }

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: var(--primary);
    min-width: 200px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 502;


    &.secondaryMenu {
      top:50px;
      right:0;
    }

    a, .menuItem {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      width:100%;
      //color: var(--white);
      font-size: 16px;

      color: var(--black);
      background-color: var(--white);


      &:hover {
        //background-color: var(--light-gray);
        background-color: var(--neutral-grey-80);
        color: var(--primary);
        cursor: pointer;
      }

      &.subMenuParent {
      display:flex;
        justify-content:space-between
      }

      &.subMenu2 {
        padding-left: 30px;
      }
    }
  }
}


.fullNavBarMask {

  position:fixed;

  top:0;
  bottom:0;
  left:0;
  right:0;

  z-index:500;

  background-color:lightgrey;
  opacity: 0.01;
}




.miniMenuSection {

  position:relative;

  z-index:501;

  height:100%;
  width:100%;

  padding-left: 0 !important;
  padding-right: 0 !important;

  background-color:var(--primary);

  .miniMenu {

    .miniMenuItem {

      min-height: 45px;
      padding: 15px 15px 15px 15px;
      border-bottom: 1px solid var(--accent-background);
      line-height: 25px;

      color: var(--white);

      display: flex;
      justify-content: space-between;
      align-items: center;

      &.subMenu1 {
        padding-left: 35px;

      }

      &.subMenu2 {
        padding-left: 50px;
      }

      img {
        margin: 0 10px 0 0;
      }

      .miniMenuItmProfile {
        margin: 0 2px 0 0;
      }


      &:hover {
        background-color: var(--light-gray) !important;
        color: var(--primary) !important;
        cursor: pointer;
      }
    }
  }
}




.miniMenuMask {

  position:fixed;

  top:0;
  bottom:0;
  left:0;
  right:0;

  z-index:500;

  background-color:lightgrey;
  opacity: 0.1;
  //background-color:red;
  //opacity: 0.5;
}





@media only screen and (min-width: 1201px) {

  .Header {

    .miniNavBar {
      display:none;
    }
  }



  .miniMenuMask, .miniMenuSection {
    display:none;
  }
}


@media only screen and (max-width: 1200px) {

  .Header {

    .fullNavBar {
      display:none;
    }

    .miniNavBar {
      display:block;
    }

    .largeLogoLeftVisible {
      display:block;
    }

    .smallLogoLeftVisible {
      display:none;
    }

    .largeLogoMiddleVisible {
      display:block;
    }

    .smallLogoMiddleVisible {
      display:none;
    }
  }
}



@media only screen and (max-width: 960px) {

  .Header {
    .largeLogoLeftVisible {
      display:none;
    }

    .smallLogoLeftVisible {
      display:block;
    }
  }
}


@media only screen and (max-width: 700px) {

  .Header {
    .largeLogoMiddleVisible {
      display:none;
    }


    .smallLogoMiddleVisible {
      display:block;
    }
  }
}



@media only screen and (max-width: 550px) {

  .Header {

    .miniNavBar {

      .miniMenuBar {

        .miniNavLogo {
          //margin-top: 5px;
        }

      }
    }
  }
}


@media only screen and (max-width: 400px) {

  .Header {

    .miniNavBar {

      //padding-left: 15px;
      //padding-right: 15px;

      .miniMenuBar {

        .miniNavLogo {
          //margin-top: 8px;
        }

      }
    }
  }
}


