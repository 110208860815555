.footer {

  //position: relative;
  //bottom: 0;
  //top:1px;
  //height:500px;

  //margin-top:20px;
  //margin-bottom:0;
  //
  //padding-bottom: 20px;
  //padding-bottom: calc( env(safe-area-inset-bottom, 0) + 20px);   // iOS Notch Issue

  //box-shadow: 0 50vh 0 50vh var(--accent-background);



  background-color: var(--primary-background);

  padding-bottom: 20px;

  .rowSpacing {
    padding-top: 22px;
    padding-bottom: 19px;

    margin-right: 0px;
    margin-left: 0px;
  }


  //ul {
  //  li {
  //    font-weight: normal;
  //  }
  //}

  .link {
    color: var(--default-text);
    gap:16px;

    //text-decoration: underline;

    a {
      color: var(--default-text);
    }
  }

  //.li{padding-top: }
}

