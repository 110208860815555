.loginHeaderContainer {
  background-color: var(--dusty-rose-40);
  min-height: 400px;
  overflow: hidden;
}

.loginHeaderLogo {
  height: 88px;
  margin-top:55px;
  margin-bottom:34px;
}


.publicHeaderContainer {
  margin-top:15px;
}

.header {

  //padding-top:55px;
  padding-bottom:10px;


  //background-color: var(--primary);

  //border-bottom: 1px solid var(--accent-background);
}

.logoImg {
  &:hover {
    cursor: pointer;
  }
}

headerLogoSmall{
  padding-bottom: 20px;
}

.brandLogoText {
  font-weight: 400;
  font-size: 36px;
}

.rightAlign {
  float: right;
}

.loginHeaderImageText {
  position: absolute;
  bottom: 30px;
  right: 0;
}

@media (max-width: 1200px) {

  .loginHeaderImageText {
    left: -55px;
  }

}

@media (max-width: 767px) {
  .loginHeaderImageText {
    left: 20px;
    bottom: 120px;
    //right: 20px;
  }

}

