.cheveron {
    //width: 20px;
    //height: 20px;
    margin: .5rem .25rem 0rem;
    margin-left: 5px;
    //margin: 0 25px 14px 143px;
    //object-fit: contain;

    font-size: 12px;
}

.flipVertical {
    transform: scaleY(-1);
}
