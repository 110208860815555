
.modal {

  .body{
    //background-color: var(--beige);
  }

  .closeBtn {
    position:absolute;
    top:10px;
    right: 10px;
  }

  .buttonRow {
    display:flex;
    justify-content: center;
    margin-top: 15px;

    .button {
      margin-top: 0;
    }

    .buttonSpacer {
      width:15px;
    }
  }
}



@media only screen and (max-width: 410px) {

  .modal {
    .buttonRow {
      flex-direction: column;

      .button {
        margin-top: 20px;
      }

      .buttonSpacer {
        display:none;
      }
    }
  }
}
