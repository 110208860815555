.main {
  min-height:60vh;
}



@media only screen and (max-width: 767px) {

  .main {
    min-height:30vh;
  }
}



